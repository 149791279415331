import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from 'i18n';
import { setLocalStorage } from 'services/common/storage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setMessageNotify } from 'store/systems';
import { LANGUAGE } from 'utils/constants';
import {
  CONSTANT_LANG,
  LanguageKey,
  LanguageType,
  checkActiveLang, getLangURL, langLabel
} from 'utils/languages';

const useLanguage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { general, translationCurrentPage } = useAppSelector((state) => state.systems);

  //* List languages
  const languageData = useMemo(() => {
    if (general?.locales) {
      return (Object.keys(general.locales) as Array<LanguageKey>).map(
        (ele) => ({ label: langLabel(ele), value: langLabel(ele) }),
      );
    }
    return [];
  }, [general]);

  const handleLogicChangeLang = async (lang: LanguageType) => {
    if (translationCurrentPage) {
      const transData = translationCurrentPage[CONSTANT_LANG[lang]];

      if (transData) {
        navigate(`${transData?.slug !== '/' ? `/${getLangURL(i18n.language)}${transData?.slug}` : ''}`);
      } else {
        navigate(i18n.language === 'vi' ? '/' : '/en');
      }
    } else {
      navigate(i18n.language === 'vi' ? '/' : '/en');
    }
  };

  const handleShowModal = useCallback(
    (_langParam: LanguageKey, message?: string, cb?: () => void) => {
      dispatch(setMessageNotify({
        isOpen: true,
        title: '',
        message: message || '',
        type: 'warning',
      }));

      if (cb) {
        cb();
      }
    },
    [dispatch],
  );

  const handleChangeLanguage = (lang: LanguageType) => {
    if (checkActiveLang(CONSTANT_LANG[lang], general?.locales)) {
      setLocalStorage(LANGUAGE, CONSTANT_LANG[lang]);
      i18n.changeLanguage(CONSTANT_LANG[lang], () => {
        handleLogicChangeLang(lang);
      });
    } else {
      handleShowModal(CONSTANT_LANG[lang], 'Hiện tại ngôn ngữ chưa khả dụng. Vui lòng quay lại sau.');
    }
  };

  const handleGoHomePage = (lang: LanguageType) => {
    if (checkActiveLang(CONSTANT_LANG[lang], general?.locales)) {
      setLocalStorage(LANGUAGE, CONSTANT_LANG[lang]);
      i18n.changeLanguage(CONSTANT_LANG[lang], () => {
        navigate(CONSTANT_LANG[lang] === 'vi' ? '/' : '/en');
      });
    } else {
      handleShowModal(CONSTANT_LANG[lang], 'Hiện tại ngôn ngữ chưa khả dụng. Vui lòng quay lại sau.');
    }
  };

  return ({
    languageData,
    handleChangeLanguage,
    handleShowModal,
    handleGoHomePage,
  });
};

export default useLanguage;
