import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import i18n from 'i18n';
import getMenusService from 'services/menus';

interface MenusState {
  list: MenusMultipleLanguage;
}

const initialState: MenusState = {
  list: {},
};

export const getMenusAction = createAsyncThunk<
  MenusResponseTypes[],
  void,
  { rejectValue: ErrorResponse }
>('menusReducer/getMenusAction', async (_, { rejectWithValue }) => {
  try {
    const response = await getMenusService();
    return response;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const menusSlice = createSlice({
  name: 'menusReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getMenusAction.fulfilled, ($state, action) => {
      const locale = i18n.language || 'vi';
      $state.list = { ...$state.list, ...{ [locale]: action.payload } };
    });
  },
});

export default menusSlice.reducer;
