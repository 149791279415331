import { TemplatePage } from 'navigations';
import { StaticSlugMultipleLanguage } from 'services/navigations/types';

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - 68 },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export const checkExternalUrl = (str?: string) => {
  if (!str) return false;
  const tareaRegex = /^(http|https)/;
  return tareaRegex.test(String(str).toLowerCase());
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const getTodayString = () => {
  const today = new Date();
  return `${today.getDate() < 10 ? `0${today.getDate().toString()}` : today.getDate().toString()}/${today.getMonth() + 1 < 10 ? `0${(today.getMonth() + 1).toString()}` : (today.getMonth() + 1)}/${today.getFullYear()}`;
};

export const getSlugByTemplateCode = (
  templateCode: TemplatePage,
  lang: string,
  staticSlug?: StaticSlugMultipleLanguage,
): string => {
  if (staticSlug) {
    const res = staticSlug?.[lang]?.find((ele) => ele.pageData?.templateCode === templateCode);
    if (res) return res.pageData?.slug || '';
    return '';
  }
  return '';
};

export const mappingURLToExternal = (path?: string) => (path ? new URL(path, window.location.origin).href : '');

export const delay = async (timeout: number) => new Promise((resolve) => setTimeout(
  () => { resolve(true); },
  timeout,
));
