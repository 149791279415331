import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import useIsMobile from 'hooks/useDeviceQueries';
import useLanguage from 'hooks/useLanguage';
import { useAppSelector } from 'store/hooks';
import mapModifiers, { checkExternalUrl } from 'utils/functions';
import { LanguageType, languagesDropdown } from 'utils/languages';

interface FooterProps {
  textDocument?: string,
  menuData?: {
    href?: string,
    target?: string,
    text?: string,
  }[],
  imageQA: string,
  href?: string,
  dataDonors: {
    text?: string,
    image: string,
    subText?: string,
  }
}

const Footer: React.FC<FooterProps> = ({
  textDocument,
  menuData,
  imageQA,
  href,
  dataDonors,
}) => {
  const { i18n } = useTranslation();
  const locales = useAppSelector((state) => state.systems.general?.locales);
  const [isOpenLanguage, setOpenLanguages] = useState(false);

  const { handleChangeLanguage } = useLanguage();

  const handleOnChangeLanguage = useCallback((value: LanguageType) => {
    setOpenLanguages(false);
    handleChangeLanguage(value);
  }, [handleChangeLanguage]);

  const { isMobile } = useIsMobile();

  return (
    <div className="o-footer no-print">
      <div className={mapModifiers('o-footer_top', (window as any).cordova ? 'alignTop' : '')}>
        {
          isMobile && locales && (
            <div className="o-footer_right_languages">
              <div
                className={mapModifiers('o-footer_right_languages_current', isOpenLanguage ? 'active' : '')}
                onClick={() => setOpenLanguages(!isOpenLanguage)}
              >
                <img
                  width={25}
                  height={16}
                  src={languagesDropdown.find((item) => item.code === i18n.language)?.icon}
                />
                <Text type="span" modifiers={['white', '14x20', 'ls-md']}>{languagesDropdown.find((item) => item.code === i18n.language)?.text}</Text>
              </div>
              <ul className={mapModifiers('o-footer_right_languages_options', isOpenLanguage ? 'active' : '')}>
                {
                  languagesDropdown.map((locale) => (
                    <li
                      className="o-footer_right_languages_item"
                      onClick={() => handleOnChangeLanguage(locale.code)}
                      key={locale.code.toString()}
                    >
                      <img width={25} height={16} src={locale.icon} />
                      <Text type="span" modifiers={['white', '14x20', 'ls-md']}>{locale.text}</Text>
                      <span className={mapModifiers('o-footer_right_languages_item_dot', locale.code === i18n.language ? 'active' : '')} />
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
        <div className="o-footer_left">
          <Text modifiers={['white', '12x18', '400', 'ls-md']} content={textDocument} />
          {!isMobile && (
            <>
              <div className="o-footer_donors">
                <Text modifiers={['white', '14x20', '400', 'ls-md', 'center']} content={dataDonors?.text} />
                <div className="o-footer_donors-image">
                  <Image size="cover" ratio="93x28" imgSrc={dataDonors.image} />
                </div>
                <Text modifiers={['white', '14x20', '400', 'ls-md', 'center']} content={dataDonors?.subText} />
              </div>
              <div className="o-footer_menu o-footer_menu_desktop">
                {menuData && menuData.map((item, index) => (
                  <div className="o-footer_item" key={`term-${index.toString()}`}>
                    <Link
                      useExternal={checkExternalUrl(item.href)}
                      href={item.href}
                      target={item?.target}
                    >
                      <Text modifiers={['14x20', '400', 'ls-md', 'white']}>
                        {item?.text}
                      </Text>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="o-footer_right">
          {
            !isMobile && locales && (
              <div className="o-footer_right_languages">
                <div
                  className={mapModifiers('o-footer_right_languages_current', isOpenLanguage ? 'active' : '')}
                  onClick={() => setOpenLanguages(!isOpenLanguage)}
                >
                  <img
                    width={25}
                    height={16}
                    src={languagesDropdown.find((item) => item.code === i18n.language)?.icon}
                  />
                  <Text type="span" modifiers={['white', '14x20', 'ls-md']}>{languagesDropdown.find((item) => item.code === i18n.language)?.text}</Text>
                </div>
                <ul className={mapModifiers('o-footer_right_languages_options', isOpenLanguage ? 'active' : '')}>
                  {
                    languagesDropdown.map((locale) => (
                      <li
                        className="o-footer_right_languages_item"
                        onClick={() => handleOnChangeLanguage(locale.code)}
                        key={locale.code.toString()}
                      >
                        <img width={25} height={16} src={locale.icon} />
                        <Text type="span" modifiers={['white', '14x20', 'ls-md']}>{locale.text}</Text>
                        <span className={mapModifiers('o-footer_right_languages_item_dot', locale.code === i18n.language ? 'active' : '')} />
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
          }
          {
            !(window as any).cordova && imageQA && (
              <div className="o-footer_right_qr">
                <div className="o-footer_image">
                  <Image size="cover" ratio="1x1" imgSrc={imageQA} />
                </div>
                <Link
                  href={href}
                  target="CaremeYeulayminh"
                >
                  <div className="o-footer_link">
                    <Icon size="16" iconName="link" />
                    <Text
                      type="span"
                      modifiers={['white', '12x18', '400', 'ls-md']}
                      content="CaremeYeulayminh.com"
                    />
                  </div>
                </Link>
              </div>
            )
          }
        </div>
        {
          isMobile && (
            <>
              <div className="o-footer_donors">
                <Text modifiers={['white', '14x20', '400', 'ls-md']} content={dataDonors?.text} />
                <div className="o-footer_donors-image">
                  <Image size="cover" ratio="93x28" imgSrc={dataDonors.image} />
                </div>
                <div className="o-footer_donors-subText">
                  <Text modifiers={['white', '14x20', '400', 'ls-md']} content={dataDonors?.subText} />
                </div>
              </div>
              <div className="o-footer_menu o-footer_menu_desktop">
                {menuData && menuData.map((item, index) => (
                  <div className="o-footer_item" key={`term-${index.toString()}`}>
                    <Link
                      useExternal={checkExternalUrl(item.href)}
                      href={item.href}
                      target={item?.target}
                    >
                      <Text modifiers={['14x20', '400', 'ls-md', 'white']}>
                        {item?.text}
                      </Text>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )
        }
      </div>
      <div className="o-footer_menu o-footer_menu_kiosk">
        {menuData && menuData.map((item, index) => (
          <div className="o-footer_item" key={`term-${index.toString()}`}>
            <Link
              useExternal={checkExternalUrl(item.href)}
              href={item.href}
              target={item?.target}
            >
              <Text modifiers={['14x20', '400', 'ls-md', 'white']}>
                {item?.text}
              </Text>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
Footer.defaultProps = {
};

export default Footer;
