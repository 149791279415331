// import React, { useState } from 'react';
import { toast } from 'react-toastify';

// import ModalSelectPrinter from 'components/organisms/ModalSelectPrinter';
import useDidMount from 'hooks/useDidMount';
import { useAppDispatch } from 'store/hooks';
import { savePrinterCode } from 'store/systems';
// import { EXTENDED_PRINTER } from 'utils/constants';

const SelectPrinter = () => {
  const dispatch = useAppDispatch();
  // const [isOpen, setIsOpen] = useState(false);

  useDidMount(() => {
    // eslint-disable-next-line prefer-destructuring
    const cordova = (window as any).cordova;
    if (cordova) {
      document.addEventListener('deviceready', () => {
        cordova.plugins?.UsbPrinter.getConnectedPrinters((result: any[]) => {
          if (result.length === 0) {
            toast.error('Không tìm thấy máy in!', { toastId: 'notFound' });
            return;
          }
          const printerList = result.map((p) => p.printername);
          cordova.plugins?.UsbPrinter.connect(printerList[0], () => {
            toast.success('Kết nối máy in thành công!', { toastId: 'connectSuccess' });
            dispatch(savePrinterCode(printerList[0]));
          }, () => {
            toast.error('Kết nối máy in không thành công!', { toastId: 'connectFail' });
          });
          // setPrinter(printerList);
          // if (printerList.length === 1 && printerList[0].split('_')[1][0] === '1') {
          //   cordova.plugins?.UsbPrinter.connect(printerList[0], () => {
          //     toast.success('Kết nối máy in thành công!', { toastId: 'connectSuccess' });
          //     dispatch(savePrinterCode(printerList[0]));
          //     localStorage.removeItem(EXTENDED_PRINTER);
          //   }, () => {
          //     toast.error('Kết nối máy in không thành công!', { toastId: 'connectFail' });
          //   });
          // } else if (isExtend) {
          //   const printerExtend = result.map((p) => (
          //  p.printername as string).split('_')[1][0] !== '1' && p.printername)[0];
          //   cordova.plugins?.UsbPrinter.connect(printerExtend, () => {
          //     toast.success('Kết nối máy in thành công!', { toastId: 'connectSuccess' });
          //     dispatch(savePrinterCode(printerExtend));
          //     localStorage.setItem(EXTENDED_PRINTER, printerExtend);
          //   }, () => {
          //     toast.error('Kết nối máy in không thành công!', { toastId: 'connectFail' });
          //   });
          // } else {
          //   setIsOpen(true);
          // }
        }, () => {
          toast.error('Không tìm thấy máy in!', { toastId: 'notFound' });
        });
      }, false);
    }
  });

  // const handleSelectPrint = (code: string) => {
  //   const cordova2 = (window as any).cordova;
  //   cordova2.plugins?.UsbPrinter.connect(code, () => {
  //     if (code.split('_')[1][0] !== '1') {
  //       localStorage.setItem(EXTENDED_PRINTER, code);
  //     }
  //     toast.success('Kết nối máy in thành công!', { toastId: 'connectSuccess' });
  //     dispatch(savePrinterCode(code));
  //     setIsOpen(false);
  //   }, () => {
  //     toast.error('Kết nối máy in không thành công!', { toastId: 'connectFail' });
  //   });
  // };
};

export default SelectPrinter;
