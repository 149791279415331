import React, {
  useMemo
} from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'components/organisms/Footer';
import i18n from 'i18n';
import { useAppSelector } from 'store/hooks';

const Mainlayout: React.FC = () => {
  const { footer } = useAppSelector((state) => state.systems);
  const { list } = useAppSelector((state) => state.menus);

  const menuFooter = useMemo(() => {
    const footerMenu = list[i18n.language]?.find((item) => item.code === 'footer')?.items;
    if (footerMenu && footerMenu.length > 0) {
      return footerMenu.map((item) => ({
        text: item.title,
        href: item.link,
        target: item.target,
      }));
    }
    return [];
  }, [list, i18n.language]);

  return (
    <div className="t-mainLayout">
      <div className="t-mainLayout_content"><Outlet /></div>
      <Footer
        textDocument={footer[i18n.language]?.blocks?.section1.textDocument.data}
        menuData={menuFooter}
        imageQA={footer[i18n.language]?.blocks?.section1.imageQR.data?.path || ''}
        dataDonors={{
          text: footer[i18n.language]?.blocks?.section1.sponsorBy.data,
          image: footer[i18n.language]?.blocks?.section1.sponsorLogo.data?.path || '',
          subText: footer[i18n.language]?.blocks?.section1.sponsorPurpose.data,
        }}
      />
    </div>
  );
};

export default Mainlayout;
