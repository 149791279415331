import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  error: 'error',
  close: 'close',
  link: 'link',
  arrowRight: 'arrowRight',
  arrowLeft: 'arrowLeft',
  checkList: 'checkList',
  addLocationRounded: 'addLocationRounded',
  mapDoctor: 'mapDoctor',
  blackCheck: 'blackCheck',
  downloadFile: 'downloadFile'
};

export type IconName = keyof typeof iconList;

export type IconSize = '16' | '20' | '24' | '32' | '40';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}
const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />);

Icon.defaultProps = {
  size: '40',
};

export default Icon;
