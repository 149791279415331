import { RedirectDataTypes, StaticSlug } from './types';

import axiosInstance from 'services/common/instance';

export const getStaticHomeService = async <T>(): Promise<PageTemplateResponseType<T>> => {
  const response = await axiosInstance.get('pages/static/home-page');
  return response.data.data;
};

export const getPageService = async <T>(
  slug: string,
): Promise<PageTemplateResponseType<T>> => {
  const response = await axiosInstance.get(`pages/${slug}`);
  return response.data.data;
};

export const getStaticSlugService = async (): Promise<StaticSlug[]> => {
  const response = await axiosInstance.get('pages/static/all');
  return response.data.data;
};

export const redirectPageService = async (path: string): Promise<RedirectDataTypes> => {
  const response = await axiosInstance.get(`redirects/show?path=${path}`);
  return response.data.data;
};
