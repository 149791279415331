import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Resource } from 'i18next';

import i18n from 'i18n';
import { getStaticSlugService } from 'services/navigations';
import { StaticSlug, StaticSlugMultipleLanguage } from 'services/navigations/types';
import { getGeneralService, getHeaderFooterService } from 'services/systems';
import {
  FooterMultipleLang,
  GeneralDataTypes,
  HeaderFooterDataTypes
} from 'services/systems/types';

type MessageNotify = {
  isOpen: boolean;
  title: string;
  message: string;
  type: 'warning' | 'success',
};
interface SystemState {
  footer: FooterMultipleLang;
  printerCode?: string;
  isSelectPrinter: boolean;
  general?: GeneralDataTypes;
  staticPages?: StaticSlugMultipleLanguage;
  feTranslations?: Resource;
  translationCurrentPage?: TranslationPage;
  messageNotify: MessageNotify;
}

const initialState: SystemState = {
  footer: {},
  isSelectPrinter: false,
  general: undefined,
  staticPages: {},
  messageNotify: {
    isOpen: false,
    title: '',
    message: '',
    type: 'success',
  },
};

export const getHeaderFootersAction = createAsyncThunk<HeaderFooterDataTypes>(
  'systemReducer/getHeaderFooterAction',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHeaderFooterService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getGeneralAction = createAsyncThunk<GeneralDataTypes>(
  'systemReducer/getGeneral',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getGeneralService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getStaticSlugAction = createAsyncThunk<StaticSlug[]>(
  'systemReducer/getStaticSlugAction',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getStaticSlugService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    savePrinterCode: ($state, action: PayloadAction<string>) => {
      $state.printerCode = action.payload;
    },
    setIsSelectPrinter: ($state, action: PayloadAction<boolean>) => {
      $state.isSelectPrinter = action.payload;
    },
    saveTranslation: ($state, action: PayloadAction<Resource>) => {
      $state.feTranslations = action.payload;
    },
    setTranslationCurrentPage($state, action: PayloadAction<TranslationPage | undefined>) {
      $state.translationCurrentPage = action.payload;
    },
    setMessageNotify($state, action: PayloadAction<MessageNotify>) {
      $state.messageNotify = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getHeaderFootersAction.fulfilled, ($state, action) => {
      const locale = i18n.language || 'vi';
      $state.footer = { ...$state.footer, ...{ [locale]: action.payload.footer } };
    });
    builder.addCase(getGeneralAction.fulfilled, ($state, action) => {
      $state.general = action.payload;
    });
    builder.addCase(getStaticSlugAction.fulfilled, ($state, action) => {
      const locale = i18n.language || 'vi';
      const staticPageLang = { ...$state.staticPages };

      if ($state.staticPages && Object.keys($state.staticPages).length > 0
        && staticPageLang && locale) {
        staticPageLang[locale] = action.payload;
        $state.staticPages = staticPageLang;
      } else if (action.payload) {
        $state.staticPages = { ...$state.staticPages, ...{ [locale]: action.payload } };
      }
    });
  },
});

export const {
  savePrinterCode,
  setIsSelectPrinter,
  saveTranslation,
  setTranslationCurrentPage,
  setMessageNotify,
} = systemSlice.actions;

export default systemSlice.reducer;
