import { HeaderFooterDataTypes, GeneralDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getHeaderFooterService = async (): Promise<HeaderFooterDataTypes> => {
  const res = await axiosInstance.get('systems/header-footer');
  return res.data?.data;
};

export const getGeneralService = async (): Promise<GeneralDataTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data?.data;
};

export const Placeholder = '';
