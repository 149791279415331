import {
  configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import menusReducer from './menus';
import pagesReducer from './pages';
import questionsReducer from './questions';
import systemsReducer from './systems';

const rootReducer = combineReducers({
  menus: menusReducer,
  questions: questionsReducer,
  systems: systemsReducer,
  pages: pagesReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
