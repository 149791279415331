import us from 'assets/images/united_kingdom.png';
import vietnamese from 'assets/images/vietnamese.png';
import { StaticSlug } from 'services/navigations/types';

export type LanguageKey = keyof LocalesType;
export type LanguageType = 'vi' | 'en';

export const CONSTANT_LANG: Record<LanguageType, keyof LocalesResponse> = {
  vi: 'vi',
  en: 'en',
};

export type LocalesType = {
  vi: LocalesItem,
  en: LocalesItem,
};

export type ConstantRoutesCodes = keyof typeof CONSTANT_ROUTES;
export type ConstantRoutesType = typeof CONSTANT_ROUTES;

export const CONSTANT_ROUTES = {
  SURVEY: 'khao-sat',
  KIOSK: 'nguon',
  HOME: '',
};

export const CONSTANT_ROUTES_EN: ConstantRoutesType = {
  SURVEY: 'survey',
  KIOSK: 'kiosk',
  HOME: '',
};

export const checkActiveLang = (
  activeLang: keyof LocalesResponse,
  listActive?: LocalesResponse,
): boolean => {
  if (listActive) {
    return !!(listActive[activeLang] as any)?.active;
  }
  return false;
};

export function getActiveLanguages(locales?: LocalesType) {
  if (locales) {
    return (Object.keys(locales) as Array<LanguageKey>).reduce(
      (prev: Array<LanguageKey>, curr: LanguageKey) => (locales[curr].active
        ? [...prev, curr] : [...prev]),
      [],
    );
  }
  return [];
}

export function getHomeLangURL(lang?: string) {
  if (lang && lang !== 'vi') return `/${lang}`;
  return '/';
}

export function getHomeLangSlug(lang?: string) {
  if (lang && lang !== 'vi') return `${lang}/`;
  return '';
}

export function getLangURL(lang?: string) {
  if (lang && lang !== 'vi') return `${lang}/`;
  return '';
}

export function getLangPageTranslation(lang?: string, isHome?: boolean) {
  if (lang && lang !== 'vi' && !isHome) return lang;
  if (lang && lang !== 'vi') return `${lang}/`;
  return '';
}

export function getLangURLFirstDash(lang?: string) {
  if (lang && lang !== 'vi') return `/${lang}`;
  return '';
}

export function convertHomeRoute(langList: LanguageKey[]) {
  return langList.map((ele) => getHomeLangSlug(ele));
}

export function convertRoute(langList: LanguageKey[], slug?: string) {
  return langList.map((ele) => `${getLangURL(ele)}${slug || ''}`);
}

export const langLabel = (key: string) => {
  switch (key) {
    case 'vi':
      return 'VN';
    case 'en':
      return 'EN';
    default:
      return '';
  }
};

export function getStaticSlug(code: ConstantRoutesCodes, lang?: string) {
  switch (lang) {
    case 'en':
      return `${CONSTANT_ROUTES_EN[code]}`;
    default:
      return `${CONSTANT_ROUTES[code]}`;
  }
}

export function convertStaticRoute(
  code: ConstantRoutesCodes,
  langList: LanguageKey[],
  noSlug?: boolean,
) {
  return langList.map(
    (ele) => `${getLangURL(ele)}${getStaticSlug(code, ele)}${noSlug ? '' : '/:slug'}`,
  );
}

export function getURLCode(lang: string, code: ConstantRoutesCodes, slug?: string) {
  return `/${getLangURL(lang)}${getStaticSlug(code, lang)}${slug ? `/${slug}` : ''}`;
}

export const getSlugByTemplateCode = (
  templateCode: string,
  staticSlug?: StaticSlug[],
): string => {
  if (staticSlug) {
    const res = staticSlug.find((ele) => ele.pageData?.templateCode === templateCode);
    if (res) return res.pageData?.slug || '';
    return '';
  }
  return '';
};

export function checkExistingPrefixLang(to?: string) {
  if (!to) return 'vi';
  const prefixLang = to.split('/');
  if (prefixLang.length > 1 && langLabel(prefixLang[1])) return prefixLang[1];
  return 'vi';
}

export const languagesDropdown: Array<{
  icon: string,
  text: string,
  code: LanguageType
}> = [
    {
      icon: vietnamese,
      text: 'Tiếng Việt',
      code: 'vi'
    },
    {
      icon: us,
      text: 'English',
      code: 'en'
    },
  ];
