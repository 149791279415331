/* eslint-disable react/button-has-type */
import React from 'react';

import loadingGif from 'assets/images/loading.gif';
import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

type Variant = 'outline' | 'primary' | 'secondary' | 'bgMustard';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  iconName?: IconName;
  iconSize?: IconSize;
  handleClick?: () => void;
  children?: React.ReactNode;
  sizes?: 'h48' | 'h60' | 'h72';
  loading?: boolean;
  rightIcon?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  variant,
  handleClick,
  children,
  sizes,
  iconName,
  iconSize = '16',
  type = 'button',
  loading,
  rightIcon,
  ...props
}) => (
  <button {...props} type={type} className={mapModifiers('a-button', variant, sizes, rightIcon && 'rightIcon')} onClick={handleClick}>
    {loading ? (
      <img className="a-button_loadingIcon" src={loadingGif} alt="loading" />
    )
      : (
        <>
          {
            iconName && (
              <div className="a-button_icon">
                <Icon iconName={iconName} size={iconSize} />
              </div>
            )
          }
          {children}
        </>
      )}
  </button>
);

Button.defaultProps = {
};

export default Button;
