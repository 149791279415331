import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import i18n from 'i18n';
import { getPageService, getStaticHomeService } from 'services/navigations';
import { PageDataMultipleLanguage } from 'services/navigations/types';

interface PagesState {
  pages: PageDataMultipleLanguage;
  loading: boolean;
}

const initialState: PagesState = {
  pages: {},
  loading: false
};

export const getPageAction = createAsyncThunk<
  PageTemplateResponseType<any>,
  { slug: string },
  { rejectValue: ErrorResponse }
>('pagesReducer/getPageAction', async (params, { rejectWithValue }) => {
  try {
    const response = await getPageService(params.slug);
    return response;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const getPageHomeAction = createAsyncThunk<
  PageTemplateResponseType<any>,
  void,
  { rejectValue: ErrorResponse }
>('pagesReducer/getPageHomeAction', async (_, { rejectWithValue }) => {
  try {
    const response = await getStaticHomeService();
    return response;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const pagesSlice = createSlice({
  name: 'pagesReducer',
  initialState,
  reducers: {
    savePageData($state, action: PayloadAction<any>) {
      const locale = i18n.language || 'vi';
      const findIdx = $state.pages[locale]?.findIndex((
        page
      ) => page.pageData.templateCode === action.payload.pageData.templateCode);

      if (findIdx > -1) {
        let temp = $state.pages[locale][findIdx];
        temp = action.payload;
        $state.pages[locale][findIdx] = temp;
      } else {
        $state.pages[locale] = $state.pages[locale]?.length > 0
          ? $state.pages[locale].concat(action.payload) : [action.payload];
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(getPageAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getPageAction.fulfilled, ($state, action) => {
      const locale = i18n.language || 'vi';
      const findIdx = $state.pages[locale]?.findIndex((
        page
      ) => page.pageData.templateCode === action.payload.pageData.templateCode);

      if (findIdx > -1) {
        let temp = $state.pages[locale][findIdx];
        temp = action.payload;
        $state.pages[locale][findIdx] = temp;
      } else {
        $state.pages[locale] = $state.pages[locale]?.length > 0
          ? $state.pages[locale].concat(action.payload) : [action.payload];
      }
      $state.loading = false;
    });
    builder.addCase(getPageAction.rejected, ($state) => {
      $state.loading = false;
    });

    builder.addCase(getPageHomeAction.fulfilled, ($state, action) => {
      const locale = i18n.language || 'vi';
      const findIdx = $state.pages[locale]?.findIndex((
        page
      ) => page.pageData.templateCode === action.payload.pageData.templateCode);

      if (findIdx > -1) {
        let temp = $state.pages[locale][findIdx];
        temp = action.payload;
        $state.pages[locale][findIdx] = temp;
      } else {
        $state.pages[locale] = $state.pages[locale]?.length > 0
          ? $state.pages[locale].concat(action.payload) : [action.payload];
      }
    });
  },
});

export const {
  savePageData,
} = pagesSlice.actions;

export default pagesSlice.reducer;
