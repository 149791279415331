import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import i18n from 'i18n';
import { getAllSurveyQuestionService } from 'services/assignments';
import { QuestionList, QuestionListMultipleLanguage } from 'services/assignments/types';

interface QuestionsState {
  list: QuestionListMultipleLanguage
}

const initialState: QuestionsState = {
  list: {}
};

export const getQuestionsAction = createAsyncThunk<
  QuestionList[],
  void,
  { rejectValue: ErrorResponse }
>('questionsReducer/getQuestionsAction', async (_, { rejectWithValue }) => {
  try {
    const response = await getAllSurveyQuestionService();
    return response;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const questionsSlice = createSlice({
  name: 'questionsReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getQuestionsAction.fulfilled, ($state, action) => {
      const locale = i18n.language || 'vi';
      const filterQuestion13 = action.payload.filter((item) => item.questionData.code.includes('question-000013'));
      const filterOtherQuestion13 = action.payload.filter((item) => !item.questionData.code.includes('question-000013'));
      const indexOfQuestion1001 = filterOtherQuestion13.findIndex((item) => item.questionData.code === 'question-000010-000001');
      filterOtherQuestion13.splice(indexOfQuestion1001 + 1, 0, ...filterQuestion13);

      $state.list = { ...$state.list, ...{ [locale]: filterOtherQuestion13 } };
    });
  },
});

export default questionsSlice.reducer;
