import { useEffect, useState } from 'react';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';

const useGA = () => {
  const location = useLocation();
  const [initializedGA4, setInitializedGA4] = useState(false);
  const { general } = useAppSelector((state) => state.systems);

  useEffect(() => {
    if (general?.others?.gaIds) {
      general?.others.gaIds.forEach((item) => {
        ReactGA4.initialize(item);
      });
      setInitializedGA4(true);
    }

    if (general?.others?.gtmIds) {
      general?.others?.gtmIds.forEach((item) => {
        TagManager.initialize({
          gtmId: item,
        });
      });
    }
  }, [general]);

  useEffect(() => {
    if (location && location) {
      ReactGA4.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location, initializedGA4]);
};

export default useGA;
